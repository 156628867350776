import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../LoadingError/LoadingBox";
import MessageBox from "../LoadingError/MessageBox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#F95C3D",
    "&:hover": {
      backgroundColor: "rgba(249, 92, 61, 0.2)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#F95C3D",
  },
}));

function AddCatalogueScreen() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("category", category);
      if (image) formData.append("image", image);

      await axios.post("/api/catalogue/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Catalogue item added successfully!");
      navigate("/catalogue");
    } catch (err) {
      setError(err.message);
      toast.error("Failed to add catalogue item.");
    }
    setLoading(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md">
      <Helmet>
        <title>Add Catalogue | Ginchoux</title>
      </Helmet>
      <h1 className="text-2xl font-bold text-center mb-6">Add Catalogue Item</h1>
      {loading && <LoadingBox />}
      {error && <MessageBox severity="error">{error}</MessageBox>}
      <form onSubmit={submitHandler} className="space-y-4">
        <div>
          <label className="font-semibold">Name</label>
          <input
            type="text"
            className="w-full border rounded-lg px-3 py-2 focus:outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="font-semibold">Category</label>
          <input
            type="text"
            className="w-full border rounded-lg px-3 py-2 focus:outline-none"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </div>
        <div>
          <label className="font-semibold">Price</label>
          <input
            type="text"
            className="w-full border rounded-lg px-3 py-2 focus:outline-none"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div>
          <label className="font-semibold">Description</label>
          <textarea
            className="w-full border rounded-lg px-3 py-2 focus:outline-none"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div>
          <label className="font-semibold">Image</label>
          <div className="flex items-center space-x-2">
            <CloudUploadIcon className="text-gray-500" />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="px-6 py-2 bg-orange-500 text-white font-semibold rounded-lg hover:bg-orange-600"
          >
            Add Item
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCatalogueScreen;