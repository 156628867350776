/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useReducer, useState, useEffect, useRef } from "react";
import { Store } from "../Store";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../LoadingError/Utils";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0.1px solid gainsboro",
  borderRadius: "1rem",
  boxShadow: "1px 2px 1px gainsboro",
  p: 4,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, userPostsLoading: true };
    case "FETCHED":
      return { ...state, userPosts: action.payload, userPostsLoading: false };
    case "FETCH_FAILED":
      return {
        ...state,
        userPostsLoading: false,
        userPostsError: action.payload,
      };
    default:
      return state;
  }
};

const reducer3 = (state, action) => {
  switch (action.type) {
    case "FETCHING":
      return { ...state, loadingChooseProfile: true };
    case "FETCHED":
      return {
        ...state,
        chooseProfile: action.payload,
        loadingChooseProfile: false,
      };
    case "FETCH_FAILED":
      return { ...state, loadingChooseProfile: false, error: action.payload };
    default:
      return state;
  }
};

export default function PostPopupTooltip(props) {
  const { dataUser } = props;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showModalUnfollow, setShowModalUnfollow] = useState(false);
  const [buttonFollow, setButtonFollow] = useState("Follow");
  const [isFollowed, setIsFollowed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const divRef = useRef(null);

  const navigate = useNavigate();

  const [{ userPosts }, dispatch] = useReducer(reducer, {
    userPosts: [],
    userPostsLoading: true,
    userPostsError: "",
  });

  // const [{ loading, error, dataUser }, dispatch2] = useReducer(reducer2, {
  //   dataUser: [],
  //   loading: true,
  //   error: "",
  // });

  const [{ chooseProfile }, dispatch3] = useReducer(reducer3, {
    chooseProfile: [],
    loadingChooseProfile: true,
    errorChooseProfile: "",
  });

  function SetProfilePicture() {
    const isNull = dataUser.picture;
    if (isNull === "null" || isNull === null) {
      return <DefaultPP />;
    } else {
      return <ProfilePicture />;
    }
  }

  function DefaultPP() {
    return (
      <img
        className="mb-[-3rem] mt-[-1.7rem] bg-[#D1D1D1] relative top-[-60px] w-[6em] h-[6em] lg:w-[8em] lg:h-[8em]"
        src="../images/pp/defaultpp.png"
        alt="defaultprofilepicture"
        style={{ borderRadius: "50%", zIndex: "1111" }}
      />
    );
  }

  function ProfilePicture() {
    return (
      <img
        className="mb-[-3rem] mt-[-1.7rem] relative top-[-60px] w-[6em] h-[6em] lg:w-[8em] lg:h-[8em]"
        src={dataUser.picture}
        alt="profilepicture"
        style={{ borderRadius: "50%", zIndex: "1111" }}
      />
    );
  }
  useEffect(async () => {
    if (userInfo === null || dataUser === null) {
      navigate("/");
      return;
    }

    dispatch({
      type: "FETCHING",
    });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/posts/showUserPost?row=3&page=0&userId=${dataUser.user_id}`,
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
      dispatch({
        type: "FETCHED",
        payload: data.data,
      });

    } catch (error) {
      dispatch({
        type: "FETCH_FAILED",
        payload: error.message,
      });
      toast.error(getError(error));
    }
  }, [userInfo, dataUser]);

  useEffect(async () => {
    dispatch3({
      type: "FETCHING",
    });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/chooseProfile?userId=${dataUser.user_id}`,
        {
          auth: {
            username: `${process.env.REACT_APP_BASIC_AUTH_USERNAME}`,
            password: `${process.env.REACT_APP_BASIC_AUTH_PASSWORD}`,
          },
        }
      );

      dispatch3({
        type: "FETCHED",
        payload: data.data,
      });
    } catch (error) {
      dispatch3({
        type: "FETCH_FAILED",
        payload: error.message,
      });
    }
  }, [dataUser]);

  useEffect(() => {
    if (userInfo === null || dataUser === null) {
      navigate("/");
      return;
    }

    try {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/checkFollowed?targetUserId=${dataUser.user_id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then((getResponse) => {
          if (getResponse.data.data.followed === true) {
            setButtonFollow("Following");
            setIsFollowed(true);
          }
        });
    } catch (error) {
      toast.error(getError(error));
    }
  }, [userInfo, dataUser]);

  function Setname() {
    return (
      <p
        style={{
          maxWidth: "50vw",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "1.5em",
        }}
      >
        {dataUser.name}
      </p>
    );
  }

  function SetUsername() {
    if (dataUser.username === null) {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {dataUser.name}
        </p>
      );
    } else {
      return (
        <p
          style={{
            maxWidth: "30vw",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          @{dataUser.username}
        </p>
      );
    }
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const wordLimit = 8; // Set your word limit here

  const typeNames = {
    1: "Product",
    2: "Service",
    3: "Event",
    4: "Job",
    5: "Insight",
    6: "Education",
  };

  const getTypeName = (postType, sdType) => {
    // if (postType ===5 && sdType.value === 1) {
    //   return "Review"; // Adjust as needed if sdType.value should dynamically change the type
    // } else if (postType ===5 &&  sdType.value === 2) {
    //   return "Question"; // Adjust as needed for sdType.value === 2
    // } else {
    return typeNames[postType] || "Unknown Type";
    // }
  };

  const typeColors = {
    Product: "bg-green-600",
    Service: "bg-blue-600",
    Event: "bg-orange-600",
    Job: "bg-purple-600",
    Insight: "bg-blue-500",
  };

  const defaultColor = "bg-gray-600"; // Default color for unknown types

  const getTypeColor = (postType, sdType) => {
    if (postType === 5) {
      if (sdType.value === 1) {
        return typeColors["Review"] || defaultColor;
      } else if (sdType.value === 2) {
        return typeColors["Question"] || defaultColor;
      }
    }

    // Default to typeColors based on postType if not Review or Question
    return typeColors[typeNames[postType]] || defaultColor;
  };

  function SetDefaultImage(postObject) {
    const { post } = postObject;
    const images = post.images || [];

    const prevImage = (event) => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      event.stopPropagation();
    };

    const nextImage = (event) => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      event.stopPropagation();
    };

    const showNavigationButtons = images.length > 1;

    if (dataUser.images.length === 0) {
      return (
        <div
          style={{
            width: "50%",
            height: "100px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <p
            className=" mt-6 text-s font-semibold"
            style={{ fontStyle: "italic", quotes: '"“" "”"' }}
          >
            “{truncateText(post.description, wordLimit)}”
          </p>
        </div>
      );
    } else {
      return (
        <div className="relative w-[10rem] h-[10rem] bg-white rounded-md">
          <img
            src={images[currentIndex]}
            alt={`img ${currentIndex + 1}`}
            className="w-full h-full object-contain rounded-lg shadow-none"
          />

          {/* {showNavigationButtons && (
            <div>
              <button
                type="button"
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full text-xl p-1"
                onClick={prevImage}
              >
                &#9664;
              </button>

              <button
                type="button"
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-full text-xl p-1"
                onClick={nextImage}
              >
                &#9654;
              </button>
            </div>
          )} */}
        </div>
      );
    }
  }

  function handleButtonFollow(e) {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      if (isFollowed === false) {
        setIsLoading(false);
        followHandler();
      } else {
        setIsLoading(false);
        setShowModalUnfollow(true);
      }
    }, 1000);
  }

  const followHandler = async () => {
    setIsLoading(true);
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/follow`,
          {
            targetUserId: dataUser.user_id,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then(() => {
          toast.success("User followed!");
          setIsLoading(false);
        });
      setButtonFollow("Following");
      setIsFollowed(true);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/sendNotif`,
        {
          postId: null,
          targetUserId: dataUser.user_id,
          notifNumber: 3,
          comment: null,
          detailId: null,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.data.token}` },
        }
      );
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const unfollowHandler = async () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/users/unfollow`,
          {
            targetUserId: dataUser.user_id,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.data.token}` },
          }
        )
        .then(() => {
          toast.success("User unfollowed!");
        });
      setShowModalUnfollow(false);
      setButtonFollow("Follow");
      setIsFollowed(false);
    } catch (error) {
      toast.error(getError(error));
    }
  };

  function handleCloseModalUnfollow() {
    setShowModalUnfollow(false);
  }

  function handleUnfollowButtonModal() {
    unfollowHandler();
  }

  function handleCancelButtonModalUnfollow() {
    setShowModalUnfollow(false);
  }
  console.log(userPosts);

  return (
    <>
      <div className="overflow-auto max-h-64 pr-4 ">
        <div className="flex flex-none w-full ">
          <div
            className="card-profile-top w-[50rem]"
            style={{
              backgroundColor: "white",
              marginTop: "1.5rem",
              height: "auto",
            }}
          >
            <div>
              <div>
                <div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className={`rounded-lg  ${
                        isFollowed
                          ? "text-[#F95C3D] border-2 border-[#F95C3D] bg-white py-2 px-4 hover:bg-[#F5F5F5]"
                          : "bg-[#F95C3D] text-white py-[0.6rem] px-4 hover:bg-orange-600"
                      }  w-28 rounded-full focus:outline-none font-bold active:scale-95 ease-linear transition-all duration-150`}
                      onClick={handleButtonFollow}
                    >
                      {isLoading ? (
                        <Box>
                          <CircularProgress
                            style={{ width: "15px", height: "15px" }}
                          />
                        </Box>
                      ) : (
                        buttonFollow
                      )}
                    </button>
                    <Modal
                      open={showModalUnfollow}
                      onClose={handleCloseModalUnfollow}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className="modal-notification-style">
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          style={{
                            marginBottom: "10%",
                            marginTop: "5%",
                            textAlign: "center",
                          }}
                        >
                          {dataUser.username === null
                            ? `Are you sure want to unfollow ${dataUser.name}?`
                            : `Are you sure want to unfollow ${dataUser.username}?`}
                        </Typography>
                        <div className="flex justify-center">
                          <button
                            type="button"
                            className="py-2 px-4 rounded-lg text-sm bg-[#F95C3D] text-white shadow hover:bg-orange-600 outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                            onClick={handleUnfollowButtonModal}
                          >
                            Unfollow
                          </button>
                          <button
                            type="button"
                            className="py-2 px-4 rounded-lg text-sm bg-white text-[#F95C3D] shadow hover:bg-[#F5F5F5] outline-none focus:outline-none ml-2 mb-1 ease-linear transition-all duration-150"
                            onClick={handleCancelButtonModalUnfollow}
                          >
                            Cancel
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </div>

                  <div className="flex justify-center">
                    <Link to={`/${dataUser.username}`}>
                      <SetProfilePicture />
                    </Link>
                  </div>
                  <div className="flex justify-center">
                    <div className="flex">
                      <Setname />
                      <div className="flex items-center ml-2">
                        {dataUser.verified ? (
                          <img
                            className="w-4 h-4"
                            alt="verified"
                            src="../../images/icon_done.png"
                          ></img>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-[-0.1rem]">
                    <SetUsername />
                  </div>
                </div>
              </div>
              <div
                className="bioContainer flex justify-center text-[16px] m-3"
                style={{
                  height: "16%",
                  wordBreak: "break-word",
                  color: "#B6B0B0",
                }}
              >
                {chooseProfile.bio
                  ? chooseProfile.bio.split("\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))
                  : "No Bio"}
              </div>
              <div className="bg-[#F5F5F5] drop-shadow-lg rounded-lg px-1 py-2">
                <div
                  className="flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div className="container">
                    <p className="flex justify-center text-base">
                      {userPosts.length === null ? "0" : userPosts.length}
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-6"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-base">
                      {chooseProfile.follower_count === null
                        ? "0"
                        : chooseProfile.follower_count}
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-6"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-base">
                      {chooseProfile.following_count === null
                        ? "0"
                        : chooseProfile.following_count}
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-6"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-base">
                      {chooseProfile.total_likes === null
                        ? "0"
                        : chooseProfile.total_likes}
                    </p>
                  </div>
                </div>
                <div
                  className="flex"
                  style={{ justifyContent: "space-evenly" }}
                >
                  <div className="container">
                    <p className="flex justify-center text-[#B6B0B0] text-sm">
                      Posts
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-4"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-[#B6B0B0] text-sm">
                      Followers
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-4"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-[#B6B0B0] text-sm">
                      Following
                    </p>
                  </div>
                  {/* <div className="border-l-[1px] border-r-[1px] border-gray-400 h-4"></div> */}
                  <div className="container">
                    <p className="flex justify-center text-[#B6B0B0] text-sm">
                      Likes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card Profile Bottom Desktop Layout */}
        <Link to={`/${dataUser.username}`}>
          <div className="flex" style={{ width: "100%", height: "100%" }}>
            <div
              className=""
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                margin: "0",
              }}
            >
              <ImageList
                sx={{ width: "100%", height: "auto" }}
                cols={3}
                ref={divRef}
              >
                {console.log(userPosts[1])}

                {userPosts.map((userPost) => (
                  <ImageListItem
                    key={userPost.slug}
                    className="cursor-pointer relative overflow-hidden"
                    style={{
                      alignItems: "center",
                      border: "0.1px solid gainsboro",
                      borderRadius: "0.5em",
                    }}
                  >
                    {console.log(userPost)}
                    <div className="absolute right-0 top-0 h-16 w-16">
                      <div
                        className={`absolute transform rotate-45 text-center font-semibold right-[-65px] top-[10px] w-[170px] h-4 z-10 ${getTypeColor(
                          userPost.post_type,
                          userPost.sd_post_type
                        )} text-white`}
                      >
                        {getTypeName(userPost.post_type, userPost.sd_post_type)}
                      </div>
                    </div>
                    <SetDefaultImage post={userPost} />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
